export enum ProductSlug {
  // main products
  WILL = "will",
  NHCD = "health-care-directive",
  FINAL = "final-arrangements",
  DPAF = "durable-power-of-attorney-for-finances",
  SLET = "letter-to-survivors",
  TRUST = "living-trust",
  TODD = "transfer-on-death-deed",

  // additional products
  LPAF = "limited-power-of-attorney-for-finances",
  PROM = "promissory-note",
  TGA = "temporary-guardianship-authorization",
  HSA = "housekeeping-services-agreement",
  GRPA = "revocation-of-power-of-attorney",
  GRHC = "revocation-of-health-care-directive",
  CCA = "child-care-agreement",
  ECA = "elder-care-agreement",
  PCA = "pet-care-agreement",
  DMV = "authorization-to-drive-a-motor-vehicle",
  FTM = "authorization-for-international-travel-with-minor",
  GBS = "general-bill-of-sale",
  HSIT = "housesitting-instructions",
  MMT = "authorization-for-minor-medical-treatment",
  CCI = "child-care-instructions",
  QUIT = "quitclaim-deed",

  // other products
  WILLMAKER_DESKTOP_DOWNLOAD = "download-desktop-version",
  EVERPLANS = "everplans",

  // ebook only
  LONG_TERM_CARE = "long-term-care",

  // divorce products
  DIVORCE = "divorce",
}
