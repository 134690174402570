import { ProductSlug } from "productSlug.enum"
import db from "./index"

export const products = [
  {
    title: "Will",
    product_name: "WillMaker Online",
    slug: ProductSlug.WILL,
    description:
      "Take control of who inherits your property and who will care for your minor children. Appoint an executor to carry out your wishes and a guardian to manage property you leave to youth.",
    position: 1,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Health Care Directive",
    product_name: "WillMaker Online",
    slug: ProductSlug.NHCD,
    description:
      "Lay out your medical care wishes and name someone to carry out your instructions.",
    position: 2,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Final Arrangements",
    product_name: "WillMaker Online",
    slug: ProductSlug.FINAL,
    description:
      "Describe your preferences for burial, cremation, memorials, obituaries, and more.",
    position: 3,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Durable Power of Attorney for Finances",
    product_name: "WillMaker Online",
    slug: ProductSlug.DPAF,
    description:
      "Name someone you trust to be on hand to manage the many financial tasks that will arise if you become incapacitated.",
    position: 4,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Letter to Survivors",
    product_name: "WillMaker Online",
    slug: ProductSlug.SLET,
    description:
      "Use this letter to explain decisions you’ve made, express affection, give advice, or leave any other last words.",
    position: 5,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Living Trust",
    product_name: "WillMaker Online",
    slug: ProductSlug.TRUST,
    description:
      "Distribute your assets and provide property management while keeping your trust property out of lengthy and expensive probate proceedings after you die.",
    position: 6,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Transfer on Death Deed",
    product_name: "WillMaker Online",
    slug: ProductSlug.TODD,
    description:
      "Use this form to avoid probate and retain ownership, responsibility, and control over your property while you’re alive. In the event of your death, ownership will automatically transfer to the beneficiary you name.",
    position: 7,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "WillMaker Download",
    product_name: "WillMaker Download",
    slug: ProductSlug.WILLMAKER_DESKTOP_DOWNLOAD,
    description: "WillMaker software is also available to download and install on your computer.",
    position: 8,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "everplans",
    product_name: "everplans",
    slug: ProductSlug.EVERPLANS,
    description:
      "Easily share your estate plan with family, close friends, and trusted advisors. Safely secure and store wills, trust, passwords, funeral wishes and so much more with a comprehensive shareable digital archive.",
    position: 19,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Premium Divorce Questions",
    product_name: "Divorce",
    slug: ProductSlug.DIVORCE,
    description: "3SD Divorce",
    position: 20,
    brand: "threesd",
    category: "Divorce",
  },
  {
    title: "Premium Divorce Questions",
    product_name: "Divorce",
    slug: ProductSlug.DIVORCE,
    description: "DIVnet Divorce",
    position: 21,
    brand: "divorcenet",
    category: "Divorce",
  },
  {
    title: "Premium Divorce Questions",
    product_name: "Divorce",
    slug: ProductSlug.DIVORCE,
    description: "Nolo Divorce",
    position: 22,
    brand: "nolodivorce",
    category: "Divorce",
  },
  {
    title: "Revocation of Health Care Directive",
    product_name: "Revocation of Health Care Directive",
    slug: ProductSlug.GRHC,
    description:
      "Revoke your health care declaration, living will, or health care power of attorney.",
    position: 23,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Revocation of Power of Attorney",
    product_name: "Revocation of Power of Attorney",
    slug: ProductSlug.GRPA,
    description: "Revoke your durable power of attorney for finances.",
    position: 24,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Quitclaim Deed",
    product_name: "Quitclaim Deed",
    slug: ProductSlug.QUIT,
    description: "Transfer your home or other real estate into your living trust.",
    position: 25,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "General Bill of Sale",
    product_name: "General Bill of Sale",
    slug: ProductSlug.GBS,
    description: "Document and detail the sale of personal property.",
    position: 26,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Limited Power of Attorney",
    product_name: "Limited Power of Attorney",
    slug: ProductSlug.LPAF,
    description: "Appoint an agent to help you with specific financial tasks.",
    position: 27,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Promissory Note",
    product_name: "Promissory Note",
    slug: ProductSlug.PROM,
    description: "Make an agreement to lend or borrow (and repay) money.",
    position: 28,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Authorization for Minor's Medical Treatment",
    product_name: "Authorization for Minor's Medical Treatment",
    slug: ProductSlug.MMT,
    description: "Give legal permission for medical care to be administered to your child.",
    position: 29,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Authorization for International Travel with Minor",
    product_name: "Authorization for International Travel with Minor",
    slug: ProductSlug.FTM,
    description: "Give a trusted adult legal authority to travel with your child.",
    position: 30,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Authorization to Drive a Motor Vehicle",
    product_name: "Authorization to Drive a Motor Vehicle",
    slug: ProductSlug.DMV,
    description: "Give legal permission for someone to borrow your car or other vehicle.",
    position: 31,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Child Care Agreement",
    product_name: "Child Care Agreement",
    slug: ProductSlug.CCA,
    description: "Make an employment agreement with your child's caregiver.",
    position: 32,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Elder Care Agreement",
    product_name: "Elder Care Agreement",
    slug: ProductSlug.ECA,
    description: "Make an employment agreement with an elder's caregiver.",
    position: 33,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Housekeeping Services Agreement",
    product_name: "Housekeeping Services Agreement",
    slug: ProductSlug.HSA,
    description: "Make an employment agreement with your housekeeper.",
    position: 34,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Child Care Instructions",
    product_name: "Child Care Instructions",
    slug: ProductSlug.CCI,
    description: "Provide instructions for the care of your child.",
    position: 35,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Housesitting Instructions",
    product_name: "Housesitting Instructions",
    slug: ProductSlug.HSIT,
    description: "Provide instructions for the care of your home.",
    position: 36,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Pet Care Agreement",
    product_name: "Pet Care Agreement",
    slug: ProductSlug.PCA,
    description: "Document the responsibilities of your pet's caregiver.",
    position: 37,
    brand: "willmaker",
    category: "WillMaker",
  },
  {
    title: "Temporary Guardianship Authorization",
    product_name: "Temporary Guardianship Authorization",
    slug: ProductSlug.TGA,
    description: "Give a trusted person temporary legal authority to care for your child.",
    position: 38,
    brand: "willmaker",
    category: "WillMaker",
  },
]

export const userProductStatuses = [
  {
    id: 1,
    name: "active",
    description: "Paid and active user product or subscription",
  },
  {
    id: 2,
    name: "paused",
    description: "Paused subscription",
  },
  {
    id: 3,
    name: "cancelled",
    description: "Cancelled subscription",
  },
  {
    id: 4,
    name: "expired",
    description: "Expired subscription",
  },
  {
    id: 5,
    name: "nonpayment",
    description: "Subscription not paid",
  },
]

export const userStatuses = [
  {
    id: 1,
    name: "active",
    description: "Normal user ",
  },
  {
    id: 2,
    name: "blocked",
    description: "Blocked user",
  },
]

/*
 * This seed function is executed when you run `blitz db seed`.
 *
 * Probably you want to use a library like https://chancejs.com
 * or https://github.com/Marak/Faker.js to easily generate
 * realistic data.
 */
const seed = async () => {
  products.map(async (product) => {
    await db.products.upsert({
      where: {
        slug_brand: {
          slug: product.slug,
          brand: product.brand,
        },
      },
      create: {
        title: product.title,
        description: product.description,
        slug: product.slug,
        position: product.position,
        product_name: product.product_name,
        brand: product.brand,
        category: product.category,
      },
      update: {
        title: product.title,
        description: product.description,
        slug: product.slug,
        position: product.position,
        product_name: product.product_name,
        brand: product.brand,
        category: product.category,
      },
    })
  })

  userProductStatuses.map(async (userProductStatus) => {
    await db.user_product_statuses.upsert({
      where: {
        id: userProductStatus.id,
      },
      create: {
        id: userProductStatus.id,
        user_product_status_name: userProductStatus.name,
        description: userProductStatus.description,
      },
      update: {
        id: userProductStatus.id,
        user_product_status_name: userProductStatus.name,
        description: userProductStatus.description,
      },
    })
  })

  userStatuses.map(async (userStatus) => {
    await db.user_statuses.upsert({
      where: {
        id: userStatus.id,
      },
      create: {
        id: userStatus.id,
        user_status_name: userStatus.name,
        description: userStatus.description,
      },
      update: {
        id: userStatus.id,
        user_status_name: userStatus.name,
        description: userStatus.description,
      },
    })
  })

  await db.access_tokens.create({
    data: {
      access_token_type: "FULFILLMENT_TOKEN",
      hashed_token: "jRyuLxcRRgPkO22DQ4KvC8Z1ciahVW2Q",
      expires_at: new Date(9999, 0),
    },
  })

  await db.access_tokens.create({
    data: {
      access_token_type: "CP_TOKEN",
      hashed_token: "ayP7ZMi7nrOxgkXLft9LsQ91MfZ38GZM",
      expires_at: new Date(9999, 0),
    },
  })
}

export default seed
