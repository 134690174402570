import Head from "next/head"
/* eslint-disable @next/next/next-script-for-ga */
import { Fragment, ReactNode, Suspense } from "react"
import FontHelper from "app/components/fonts/FontHelper"
import Navbar from "app/components/Navbar"
import Footer from "app/components/Footer"
import { getFaviconForTenant, getTenant } from "app/utils/tenants"
import { Provider as BusProvider } from "react-bus"
import {
  getGoogleAnalytics4ForTenant,
  getGoogleAnalyticsForTenant,
  getGoogleTagManagerForTenant,
} from "app/utils/analytics"
import { useTheme } from "@chakra-ui/react"
import { useTenant } from "app/components/common/Tenant"

type LayoutProps = {
  title?: string
  children: ReactNode
}

const Layout = ({ title, children }: LayoutProps) => {
  const theme = useTheme()
  const { tenant } = useTenant()
  const tenantName = tenant?.name
  const favicon = getFaviconForTenant(tenantName)
  const googleAnalytics = getGoogleAnalyticsForTenant(tenantName)
  const googleTagManager = getGoogleTagManagerForTenant(tenantName)
  const googleAnalytics4 = getGoogleAnalytics4ForTenant(tenantName)

  return (
    <>
      <Head>
        {theme.primaryFonts
          .filter(({ preload }) => preload)
          .map(({ name, weights }) => (
            <Fragment key={name}>
              {FontHelper.renderFontPreloads(name, { weights, format: "woff2" })}
            </Fragment>
          ))}
        <title>{title || "Consumer Portal"}</title>
        <link rel="icon" href={favicon} />

        {/*<!-- Global site tag (gtag.js) - Google Analytics -->*/}
        {googleAnalytics && (
          <>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalytics}`} />
            <script
              async
              dangerouslySetInnerHTML={{
                __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${googleAnalytics}', {
              page_path: window.location.pathname,
            });
          `,
              }}
            />
          </>
        )}
        {/* {<!-- Google Tag Manager -->} */}
        {googleTagManager && (
          <>
            <script
              async
              dangerouslySetInnerHTML={{
                __html: `
          (function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${googleTagManager}');
        `,
              }}
            />
          </>
        )}
        {/* {<!--End Google Tag Manager-->} */}

        {/* Google Analytics 4 */}
        {googleAnalytics4 && (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalytics4}`}
            ></script>
            <script
              async
              dangerouslySetInnerHTML={{
                __html: `
          window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', "${googleAnalytics4}");
        `,
              }}
            />
          </>
        )}
      </Head>
      <BusProvider>
        <Suspense fallback="Loading...">
          <Navbar />
        </Suspense>
        {children}
        <Footer />
      </BusProvider>
    </>
  )
}

export default Layout
