import { Container, Flex, Text, Link } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { useRouter } from "next/router"
import { useTenant } from "./common/Tenant"

const FooterContainer = ({ page, children }) => {
  // list of pages that have gray background footer on mobile
  switch (page) {
    case "/divorcenet/documents":
    case "/threesd/documents":
    case "/threesd/help":
    case "/divorcenet/help":
    case "/nolodivorce/documents":
    case "/nolodivorce/help":
      return (
        <Container size="xl" background={{ base: "#F2F2F2", md: "white" }}>
          {children}
        </Container>
      )
      break
    default:
      return <Container size="xl">{children}</Container>
  }
}

function Footer() {
  const {tenant} = useTenant()
  const router = useRouter()

  const themeName = tenant?.name

  let brand
  let copyrightAddendum = ""
  switch (themeName) {
    case "threesd":
      brand = "3StepDivorce"
      break
    case "divorcenet":
      brand = "DivorceNet"
      break
    default:
      brand = "Nolo"
      copyrightAddendum =
        "Nolo is the publisher of WillMaker. Quicken is a registered trademark of Rocket Mortgage, LLC, used under license."
      break
  }

  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) return null

  return (
    <FooterContainer page={router.pathname}>
      <Flex
        alignItems={"center"}
        pt={{ base: "29px", lg: "79px" }}
        pb={{ base: "74px", lg: "104px" }}
        px={{ base: "0px", lg: "50px" }}
        width={{ lg: "1128px", md: "720px", sm: "382px" }}
        margin="0 auto"
      >
        <Text fontSize={"14px"} lineHeight={"22px"} color={"gray.700"} textAlign={"center"}>
          <Link variant="brand" href="https://www.nolo.com/about/copyright-policy.html" isExternal>
            Copyright © {new Date().getFullYear()} MH Sub I, LLC dba {brand} ®
          </Link>{" "}
          {copyrightAddendum} Self-help services may not be permitted in all states. The information
          provided on this site is not legal advice, does not constitute a lawyer referral service,
          and no attorney-client or confidential relationship is or will be formed by use of the
          site. The attorney listings on this site are paid attorney advertising. In some states,
          the information on this website may be considered a lawyer referral service. Please
          reference the{" "}
          <Link variant="brand" href="https://www.internetbrands.com/ibterms/" isExternal>
            Terms of Use
          </Link>{" "}
          and the
          <Link
            variant="brand"
            href="https://www.internetbrands.com/ibterms/supplementallegalterms/"
            isExternal
          >
            {" "}
            Supplemental Terms
          </Link>{" "}
          for specific information related to your state. Your use of this website constitutes
          acceptance of the{" "}
          <Link variant="brand" href="https://www.internetbrands.com/ibterms/" isExternal>
            {" "}
            Terms of Use
          </Link>
          ,
          <Link
            variant="brand"
            href="https://www.internetbrands.com/ibterms/supplementallegalterms/"
            isExternal
          >
            {" "}
            Supplemental Terms
          </Link>
          ,
          <Link
            variant="brand"
            href="https://www.internetbrands.com/privacy/privacy-main.html"
            isExternal
          >
            {" "}
            Privacy Policy
          </Link>{" "}
          and{" "}
          <Link
            variant="brand"
            href="https://www.internetbrands.com/privacy/cookie-policy.html"
            isExternal
          >
            {" "}
            Cookie Policy
          </Link>
          .
          {themeName === "willmaker" ? (
            <Link
              variant="brand"
              href="https://www.internetbrands.com/privacy/privacy-contact-form.php"
              isExternal
            >
              &nbsp;&nbsp;Do Not Sell My Personal Information
            </Link>
          ) : (
            ""
          )}
        </Text>
      </Flex>
    </FooterContainer>
  )
}

export default Footer
