import { user_products } from "db"
import { products } from "db/seeds"
import { ProductSlug } from "productSlug.enum"

export const getValidProductsFromTheme = (
  myTheme: string,
  hiddenProducts: string[] = [],
  isQuickenCustomer: boolean = false
) => {
  return (
    products &&
    products.filter(
      (p) =>
        p.brand.toLocaleLowerCase() === myTheme &&
        !hiddenProducts.includes(p.slug) &&
        (!isQuickenCustomer || p.slug !== ProductSlug.EVERPLANS)
    )
  )
}

export const getValidDownloadProduct = (myTheme: string, hiddenProducts: string[] = []) => {
  const validProducts = getValidProductsFromTheme(myTheme, hiddenProducts)
  return (
    validProducts && validProducts.filter((p) => p.slug === ProductSlug.WILLMAKER_DESKTOP_DOWNLOAD)
  )
}

export default getValidProductsFromTheme
