import { useQuery } from "@blitzjs/rpc"
import { useEffect } from "react"
import getInterviews from "app/core/queries/getInterviews"
import { updateInterviews } from "app/redux/slices/interviewsSlice"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "app/redux/store"

export function useProductInterviews() {
  const dispatch = useDispatch()
  const productsInterviews = useSelector((state: RootState) => state.interviews)

  const [interviews] = useQuery(
    getInterviews,
    {},
    {
      staleTime: 900000,
      enabled: Object.keys(productsInterviews).length == 0 ? true : false,
    }
  )

  useEffect(() => {
    dispatch(updateInterviews(interviews))
  }, [dispatch, interviews])

  return productsInterviews
}
